import * as React from "react";
const SvgBattery = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 120 120", ...props }, /* @__PURE__ */ React.createElement("g", { transform: "translate(0,-952.36218)" }, /* @__PURE__ */ React.createElement("path", { style: {
  textIndent: 0,
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  baselineShift: "baseline",
  color: "#000000",
  enableBackground: "accumulate"
}, d: "m 23,17 c -1.337834,0 -2.7342,0.52416 -3.65625,1.5 C 18.4217,19.47584 18,20.78385 18,22 l 0,4 -8,0 c -2.7181084,0 -5,2.28189 -5,5 l 0,51 c 0,2.7181 2.2818916,5 5,5 l 80,0 c 2.718108,0 5,-2.2819 5,-5 l 0,-51 c 0,-2.71811 -2.281892,-5 -5,-5 l -8,0 0,-4 C 82,20.78385 81.578312,19.47583 80.65625,18.5 79.734188,17.52417 78.33781,17 77,17 l -12,0 c -1.33781,0 -2.734188,0.52417 -3.65625,1.5 C 60.421688,19.47583 60,20.78385 60,22 l 0,4 -20,0 0,-4 C 40,20.78385 39.5783,19.47584 38.65625,18.5 37.7342,17.52416 36.337834,17 35,17 z m 1,6 10,0 0,3 -10,0 z m 42,0 10,0 0,3 -10,0 z m -55,9 78,0 0,2 -78,0 z m 0,8 78,0 0,33 -78,0 z m 16.96875,5.9375 C 26.397974,45.953934 24.98371,47.397973 25,48.96875 L 25,54 c -1.754485,-9.4e-5 -3.584017,0 -5.3125,0 -1.570984,0.08155 -2.926091,1.585307 -2.84427,3.156277 C 16.925051,58.727247 18.429044,60.082094 20,60 l 5,0 0,5.03125 c -0.02241,1.584938 1.414904,3.042726 3,3.042726 1.585096,0 3.022415,-1.457788 3,-3.042726 L 31,60 l 5,0 c 1.584938,0.02242 3.042726,-1.414904 3.042726,-3 0,-1.585096 -1.457788,-3.022415 -3.042726,-3 l -5,0 0,-5.03125 c 0.0165,-1.591609 -1.439641,-3.047755 -3.03125,-3.03125 z M 62.6875,54 c -1.570984,0.08155 -2.926091,1.585307 -2.84427,3.156277 C 59.925051,58.727247 61.429044,60.082094 63,60 l 17,0 c 1.584938,0.02242 3.042726,-1.414904 3.042726,-3 0,-1.585096 -1.457788,-3.022415 -3.042726,-3 -5.758741,-9e-6 -11.578046,0 -17.3125,0 z M 11,79 l 78,0 0,2 -78,0 z", transform: "translate(0,952.36218)", fill: "#000000", fillOpacity: 1, stroke: "none", marker: "none", visibility: "visible", display: "inline", overflow: "visible" })));
export default SvgBattery;
